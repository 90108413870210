<template>
  <module-actions :value="item.id" @delete="onDelete">
    <template #append-items>
      <v-list-item
        :class="{ 'primary--text': canCreateInvoices }"
        :disabled="!canCreateInvoices"
        @click="onCreateContractInvoices"
      >
        <v-list-item-content>
          <v-list-item-title v-text="$t('contract.create.invoices')" />
        </v-list-item-content>
      </v-list-item>
    </template>
  </module-actions>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Contract, ContractData } from "@planetadeleste/vue-mc-gw";
import { get } from "lodash";

@Component
export default class ContractListActions extends Vue {
  @Prop(Object) readonly item!: ContractData | Contract;

  get canCreateInvoices(): boolean {
    return this.item instanceof Contract
      ? (this.item.get("can_create_invoice") as boolean)
      : get(this.item, "can_create_invoice", false);
  }

  onCreateContractInvoices() {
    this.$emit("createinvoices", this.item);
  }

  onDelete(sValue: number) {
    this.$emit("delete", sValue);
  }
}
</script>
